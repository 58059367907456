.luxury-navbar {
    width: 80px;
    background-color: #610b55; /* Dark, elegant background */
    color: #ffffff;
    padding: 2em;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    position: fixed;
    top: 102px; /* Adjust for TopNavbar height */
    bottom: 102px;
    left: 0;
    height: calc(100% - 50px);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    font-family: 'Comic Sans MS', cursive; /* Luxury font */
}

/* Sidebar Link Styles */
.sidebar-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-item {
    margin: 1em 0;
}

.sidebar-item a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s ease;
}

.sidebar-item a:hover {
    color: #d3af6e; /* Gold color on hover */}