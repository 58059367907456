/* Element.css */

/* Adjust the overall container to have proper spacing from the top navigation bar */
.element-detail {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 600px;
  margin: calc(var(--navbar-height, 100px) + 20px) auto 20px; /* Add margin-top for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Remove top positioning */
}

  
  /* Optional: Adjust for mobile view or smaller screens */
  @media (max-width: 768px) {
    .element-detail {
      top: calc(var(--navbar-height, 60px) + 10px); /* Slightly smaller offset for mobile screens */
      margin: 10px auto; /* Reduce side margins for smaller screens */
    }
  }
  
  /* Title styling */
  .element-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Element information */
  .element-info {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Spacing between details */
  .element-info div {
    margin-bottom: 8px;
    padding: 5px;
    transition: background-color 0.3s, transform 0.2s;
    border-radius: 4px;
  }
  
  /* Hover effect for details */
  .element-info div:hover {
    background-color: #f0f8ff; /* Light blue background on hover */
    transform: scale(1.02); /* Slight zoom-in effect */
    cursor: pointer; /* Pointer cursor to indicate interactivity */
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    text-align: center;
  }
  
  /* Wikipedia link */
  .wikipedia-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .wikipedia-link:hover {
    text-decoration: underline;
  }
  
  /* More details button */
  .more-details-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }
  
  .more-details-button:hover {
    background-color: #0056b3;
  }
  