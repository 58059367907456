/* Space for top navbar */
.compound-page {
  margin-top: 100px; /* Adjust based on your top navbar height */
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the selection box horizontally */
  padding: 0 20px; /* Add padding for responsive layout */
  box-sizing: border-box; /* Include padding in width calculations */
}

/* Top Bar (Filter Section) */
.top-bar {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the entire top bar horizontally */
  background-color: #f4f4f4;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px; /* Limit the width for larger screens */
}

/* Styling for the category label */
.category-label {
  margin-right: 10px;
  font-weight: bold;
  text-align: center;
}

/* Styling for the category dropdown */
.category-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
  max-width: 300px; /* Make dropdown box visually consistent */
}

/* Responsive grid layout for compounds */
.compounds-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Flexible grid layout */
  gap: 20px; /* Space between grid items */
  width: 100%; /* Use full width of the container */
  padding: 0 20px; /* Add padding for consistent spacing */
  box-sizing: border-box; /* Ensure padding doesn't affect width calculations */
  margin: 0 auto; /* Center the grid */
}

/* Compound Card */
.compound-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.compound-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Compound Image */
.compound-image {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Compound Name */
.compound-name {
  font-size: 1.2rem;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50;
}

/* Compound Formula */
.compound-formula {
  font-size: 1rem;
  color: #555;
}

/* Link styling for compound card */
.compound-card-link {
  text-decoration: none;
  color: inherit;
}
