.bottom-bar {
    background-color: #333;
    color: #fff;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 0px;
  }
  
  .bottom-bar-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
  }
  
  .bottom-bar-section {
    flex: 1;
    min-width: 200px;
  }
  
  .bottom-bar-section h4 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #f9f9f9;
  }
  
  .bottom-bar-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .bottom-bar-section ul li {
    margin: 5px 0;
  }
  
  .bottom-bar-section ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .bottom-bar-section ul li a:hover {
    text-decoration: underline;
  }
  
  .social-icons a {
    margin-right: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    text-decoration: underline;
  }
  
  .bottom-bar-footer {
    margin-top: 20px;
    text-align: center;
    font-size: 0.9rem;
    color: #bbb;
  }
  