.auth-container {
    width: 300px;
    margin: 50px auto;
    padding: 30px;
    background-color: #fdfcf7;
    border: 1px solid #c5a880;
    font-family: 'Garamond', serif;
}

.auth-container h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.auth-container form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #c5a880;
    background-color: #fafafa;
}

.auth-container button {
    width: 100%;
    padding: 10px;
    background-color: #1a1a1a;
    color: #f5f5f5;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.auth-container button:hover {
    background-color: #c5a880;
}

.auth-container p {
    text-align: center;
    margin-top: 15px;
}
/* Auth.css */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: #f4f4f4; /* Neutral background */
    border: 1px solid #d3af6e; /* Gold border */
    border-radius: 8px;
    width: 100%;
    max-width: 320px;
    margin: 20px auto;
}

.custom-button {
    margin-top: 20px;
    background-color: #d3af6e; /* Old money gold */
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.custom-button:hover {
    background-color: #b29658;
}
#my-signin2 {
    margin-top: 15px;
}

/* Additional styling for a luxury "old money" theme */
.auth-container h2 {
    font-family: 'Times New Roman', serif;
    font-weight: bold;
    color: #333; /* Deep, elegant color */
}

.custom-button {
    background-color: #d3af6e;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
}

.custom-button:hover {
    background-color: #b29658;
}