/* Top Navbar */
.top-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    background-color: #610b55;
    color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 1000;
    text-align: center;
}

.navbar-actions {
    margin-left: auto; /* Pushes the account icon to the right */
    margin-right: 20px; /* Adds margin on the right side */
}


.navbar-branding {
    display: flex;
    justify-content: space-between; /* Center the branding link */
    align-items: center; /* Align items vertically */
    width: 100%; /* Allow it to take full width */
    margin-bottom: 10px;
}

.navbar-brand {
    font-family: 'Comic Sans MS', cursive;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
}

.navbar-motto {
    font-size: 0.9em;
    font-style: italic;
    color: #bfbfbf;
    margin: 0;
}

.navbar-links {
    display: flex;
    gap: 1.5em;
    list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.1em;
}

.navbar-links a:hover {
    color: #d3af6e; /* Gold on hover */
}
.branding-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color to keep it consistent */
    cursor: pointer;
}

.branding-link:hover {
    opacity: 0.9; /* Slight opacity on hover for subtle effect */
}
.account-icon {
    margin-left: auto; /* Pushes the icon to the right end */
    display: flex;
    
    font-size: .5rem; /* Adjust as needed */
    color: #b29600; /* Luxury color to match old money theme */
}

.account-icon svg {
    width: 32px; /* Set the width of the SVG */
    height: 32px; /* Set the height of the SVG */
}

.account-icon:hover {
    color: #d3af6e; /* Change color on hover */
}

