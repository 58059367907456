/* src/App.css */

/* Centered the heading */
.loader {
  position: fixed; /* Fixed position relative to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to the center of top and left */
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}


@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Error Message */
.error-message {
  color: #e74c3c;
  font-size: 1.2rem;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border: 1px solid #e74c3c;
  border-radius: 5px;
}

.App {
  display: flex;
  
  flex-direction: column;
  min-height: 100vh; /* Ensures the app spans the full viewport height */
}

h1 {
  font-size: 2.5em; /* Adjust font size */
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  text-align: center; /* Center the text */
}


/* Periodic table grid */
.periodic-table {
  /*background-color: rgba(255, 0, 0, 0.1);
  transform: scale(1); 
  grid-column: 1 / 19; /* Spanning full grid width */
  grid-row: 3 / 11; /* Start rendering below the legend */
  display: grid;
  grid-template-columns: repeat(18, 1fr); /* 18 columns for periodic table layout */
  grid-gap: 8px;
  padding: 1em;
  margin-bottom: 0; /* Remove unwanted bottom margin */
  padding-bottom: 0; /* Ensure no extra padding */
  }

/* Legend within the grid */
.legend-in-grid {
  grid-column: 3 / 13; /* Spanning from column 3 to 12 */
  grid-row: 1 / 4; /* Spanning rows 1 to 2 */
  background: #f9f9f910;
 /* font-family: 'Comic Sans MS', cursive;*/
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100; /* Ensure it layers above other grid items */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.legend-header {
  position: absolute; /* Ensure it is positioned relative to the parent */
  top: 0; /* Stick to the top of the parent */
  left: 0; /* Align with the parent's left edge */
  right: 0; /* Align with the parent's right edge */
  justify-content: center;
  margin: 0; /* Remove margin for precise alignment */
  width: 100%; /* Ensure it spans the full width of the parent */
  background-color: inherit; /* Match the parent's background color */
  z-index: 2; /* Ensure it stays above other elements inside the parent */
}


.grouping-toggle {
  padding: 10px 20px;
  background-color: #ddd;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.grouping-toggle:hover {
  background-color: #ccc;
}

.legend-container {
  
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend-color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ddd;
}

.legend-label {
  font-size: 14px;
}
/* Sidebar adjustment */


/* Uniform color for element cards */
.element-card {
  background-color: #f5f5f5; /* Single uniform color */
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  transition: transform 0.3s;
  position: relative;
  border: 1px solid #ddd; /* Outer border only */
  padding: 10px;
  box-shadow: none; /* Ensure no shadow is creating an overlay */
  grid-column-start: auto; /* Keep positioning dynamic */
  grid-row-start: auto;
}

/* Remove any unwanted border or shadow from child elements */
.element-card * {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  background-color: inherit; /* Inherit background from the parent to avoid overlay */
}

/* Hover effect with slight scaling */
.element-card:hover {
  transform: scale(1.05);
}

/* Text styles within element cards */
.element-symbol {
  font-size: 20px; /* Reduced size */
  font-weight: bold;
  color: #ffffff;
}

.element-name {
  font-size: 12px;
  color: #dedede;
  margin: 5px 0;
}

.element-atomic-number {
  font-size: 10px;
  color: #ffffff;
}

.element-link {
  text-decoration: none;
  color: inherit;
}

.element-link:hover {
  background-color: #ddd;
}



/* Element detail box */
.element-detail {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 450px; /* Adjusted width */
  margin: 20px auto; /* Centered detail box */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Info styling */
.element-info div {
  margin-bottom: 10px;
  font-size: 14px;
}

.element-info strong {
  font-weight: bold;
}

a {
  display: inline-block;
  margin-top: 20px;
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Sidebar styles */
.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.hamburger-icon span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.hamburger-icon.open span:nth-child(1) {
  transform: rotate(45deg);
  position: relative;
  top: 6px;
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
  position: relative;
  top: -6px;
}

.sidebar-toggle {
  position: fixed;
  top: 1em;
  left: 1em;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1100;
}

/* Style for the ionization energy toggle button */
.ionization-energy-toggle {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #ddd;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.ionization-energy-toggle:hover {
  background-color: #ccc;
}

/* Style for the arrow */
.arrow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Make the arrow non-interactive */
}

.arrow {
  position: absolute;
  width: 100%;
  height: 100%;
}