/* src/components/Arrow.css */

.arrow-container {
    position: absolute; /* Allows the arrow to overlay other elements */
    width: 100%; /* Full width of the parent container */
    height: 100%; /* Full height of the parent container */
    pointer-events: none; /* Ensure the arrow is non-interactive */
    /*background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black for debugging */

  }
  
  .arrow {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* Make the arrow line thicker */
  .arrow line {
    stroke-width: 5000; /* Adjust thickness (default is 2) */
  }
  .arrow-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none; /* Prevent interaction with the arrow */
  }
  