/* Adjust the overall container to have proper spacing from the top navigation bar */
.compound-detail {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 600px;
    margin: calc(var(--navbar-height, 100px) + 20px) auto 20px; /* Add margin-top for spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Remove top positioning */
    font-family: Arial, sans-serif;
  }
  
  /* Optional: Adjust for mobile view or smaller screens */
  @media (max-width: 768px) {
    .compound-detail {
      margin: 10px auto; /* Reduce side margins for smaller screens */
    }
  }
  
  /* Title styling */
  .compound-detail-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Compound details wrapper for images and information */
  .compound-details-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  /* Compound information styling */
  .compound-info {
    flex: 2;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .compound-info p {
    margin-bottom: 8px;
  }
  
  .compound-info p strong {
    color: #333;
  }
  
  .compound-info p span {
    display: block; /* Ensure consistent spacing for hover effects */
    padding: 5px;
    transition: background-color 0.3s, transform 0.2s;
    border-radius: 4px;
  }
  
  .compound-info p span:hover {
    background-color: #f0f8ff; /* Light blue background on hover */
    transform: scale(1.02); /* Slight zoom-in effect */
    cursor: pointer; /* Pointer cursor to indicate interactivity */
  }
  
  /* Link to external resources */
  .compound-info a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .compound-info a:hover {
    text-decoration: underline;
  }
  
  /* Compound images */
  .compound-images {
    flex: 1;
    text-align: center;
  }
  
  .compound-image {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .compound-image:hover {
    transform: scale(1.05);
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    text-align: center;
  }
  
 
  