.atomic-container {
  /*transform: scale(.7);*/
  padding: 20px;
  margin-top: 100px;
  align-items: center; /* Center horizontally */
  background-color: #f9f9f9; /* Light background for better readability */
    /* No left margin adjustments to avoid interfering with parent styles */
  }
  
  .atomic-title {
    font-size: 2rem;
    margin-bottom: 20px;
    
  }
  
  .atomic-list {
    list-style: none;
    padding: 0;
    align-items: center; /* Center horizontally */
  }
  
  .atomic-list-item {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack child elements vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    margin-bottom: 10px;
    border: 1px solid #ddd; /* Subtle border to separate items */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-height: 300px; /* Optional: Ensure enough space for centering */
    text-align: center; /* Center text inside the component */
  }
  
  
  .atomic-image {
    width: 100%; /* Scale the image to fit the container's width */
    max-width: 1100px; /* Optional: Limit the maximum width of the image */
    /*height: auto; /* Maintain aspect ratio while scaling */
    /*max-height: 100vh; /* Limit the height to a maximum of 50% of the viewport height */
    object-fit: contain; /* Ensure the image is scaled within the container without cropping */
    display: block;
    margin: 20px 0;
    border-radius: 8px; /* Optional: Add rounded corners */
  }
  
  
  .atomic-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .atomic-description {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #555; /* Subtle color for text */
  }
  