/* src/components/GoBackButton.css */
.go-back-button {
    display: inline-block;
    margin: 30px 0;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #2c2c2c; /* Dark, classic text color */
    background-color: #f5f5f5; /* Soft, understated background color */
    border: 2px solid #e2d6c9; /* Subtle, luxurious border */
    border-radius: 5px;
    font-family: 'Georgia', serif; /* Classic font choice */
    cursor: pointer;
    transition: all 0.3s ease;
}

.go-back-button:hover {
    background-color: #e2d6c9; /* A refined color shift on hover */
    color: #ffffff;
}
